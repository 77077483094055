const Tracking = {
  Active: false,

  TrackEvent:function(category, action, label, value) {
    if(!Tracking.Active) { return }

    try {
        dataLayer.push({
            'event': 'UAEvent', // This may not be changed! Is needed for GTM.
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label || '',
            'eventValue': value || ''
        })
    } catch(err) { }
  },

  Init:function() {
    Tracking.Active = true
  }
}

export default Tracking
