import Bugsnag from '@bugsnag/js'

import 'alpine-turbolinks-adapter'
import 'alpinejs'
import Tracking from 'core/Tracking'

require("@rails/ujs").start()
require("turbolinks").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Bugsnag.start({
  apiKey: 'be3598b8967a0460378b8b6947b34596',
  enabledReleaseStages: [ 'production' ]
})


// Monkey-patch for flickering flash messages. https://www.michielsikkes.com/remove-flickering-flash-messages-on-turbolinks/
document.addEventListener("turbolinks:before-cache", function() {
   const flash_message_element = document.querySelector(".flash")
   if (flash_message_element) {
     flash_message_element.remove()
   }
 })

try {
  Tracking.Init()

  document.addEventListener("turbolinks:load", function() {
    document.querySelectorAll('.track-event')
      .forEach(elem => elem.addEventListener('click', event => {
        let trackLink = event.target

        let category = trackLink.dataset.eventCategory
        let action = trackLink.dataset.eventAction
        let label = trackLink.dataset.eventLabel
        let val = trackLink.dataset.eventValue

        Tracking.TrackEvent(category, action, label, val)
      }))
  })
} catch {}
